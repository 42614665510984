import { createColumnHelper } from '@tanstack/react-table'
import { t } from 'i18next'
import moment, { Moment } from 'moment'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { IStatusTypes } from 'src/components/status-dropdown/TableStatusDropdown'
import TableStatusLabel, {
  IStatusCell,
} from 'src/components/status-dropdown/TableStatusLabel'
import CheckBox from 'src/components/switchHoc/CheckBox'
import DualTableSelector, {
  IGetAPI,
  IIsOptional,
} from 'src/components/table-ResponsibleEditor/DualTableSelector'
import TableSelectorEditor from 'src/components/table-ResponsibleEditor/TableSelectorEditor'
import TableTextEditor from 'src/components/table-texteditor/TableTextEditor'
import { Discipline } from 'src/components/timeline/types'
import UserIcon from 'src/components/user/UserIcon'
import DateTimeInput from 'src/document/components/DateTimeInput'
import DocListFileCount from 'src/document/components/FolderDocument/DocListFileCount'
import { IProjectFile } from 'src/document/types/IFileContainer'
import {
  IContract,
  IImprovement,
  IKeypoint,
  IMainProcess,
  IMilestone,
  ITag,
  ITeam,
  IUserData,
} from 'src/service/OrgTypes'
import TableColumn from 'src/ui-elements/Table/TableColumn'
import MultiValueListSelector from 'src/ui-elements/Table/cells/MultiValueListSelector'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import { ISingleFilter } from 'src/ui-elements/list/ListContextProvider'
import { filterType } from 'src/ui-elements/list/ListTypes'
import { toFixed } from 'src/utility/Utility'
import { classNames, openFile } from 'src/utility/utils'

export const styleClass = {
  cell: classNames('px-1 truncate'),
  editableCell: classNames(
    'hover:bg-blue-root-tab-wash focus:bg-white',
    'px-1 truncate',
    'cursor-text',
  ),
  disciplineCell: classNames('flex flex-row px-1 w-fit'),
}

export interface CustomMeta {
  name: string
  disabled?: boolean
  getFilter?: () => Promise<ISingleFilter[]>

  // Use only if the data in the export is parsed differently than that in the web list
  // For old column definitions, use filterDataField
  field?: string
}

// ==== NEW table columns
type DefaultColumn = {
  record_id: string
  created_at: string
  updated_at: string
  baseline: string
  closed_date: string
  title: string
  description: string
  subject: string
  control_area_title: string
  ['current_revision.name']: string
  ['current_revision.start_time']: string
  ['current_file_container_step.duedate']: string
  ['folder.name']: string
  event: string
  taskType: string
  deadline: string
}

const defaultHelper = createColumnHelper<DefaultColumn>()

export const textColumn = (field: keyof DefaultColumn, meta: CustomMeta) =>
  defaultHelper.accessor(field, {
    id: field,
    size: 150,
    cell: ({ cell }) => (
      <span className={styleClass.cell}>{cell.getValue()}</span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

export const dateColumn = (
  field: keyof DefaultColumn,
  meta: CustomMeta,
  showExpired?: boolean,
  withTime?: boolean,
) =>
  defaultHelper.accessor(field, {
    id: field,
    size: withTime ? 150 : 120,
    cell: ({ cell }) => (
      <span
        className={twMerge(
          styleClass.cell,
          showExpired &&
            moment(cell.getValue()).isBefore(moment(), 'd') &&
            'text-red',
        )}
      >
        {cell.getValue()
          ? moment(cell.getValue()).format(withTime ? 'lll' : 'L')
          : ''}
      </span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.RANGEDATE,
      processFilter: (val: { start: string; end: string }) => ({
        [field]: [val.start, val.end],
      }),
      ...meta,
    },
  })

type FileColumn = {
  project_files: IProjectFile[]
  ['current_revision.project_files']: IProjectFile[]
}

const fileHelper = createColumnHelper<FileColumn>()

export const fileColumn = (field: keyof FileColumn, meta: CustomMeta) =>
  fileHelper.accessor(field, {
    id: field,
    size: 150,
    cell: ({ cell }) => (
      <DocListFileCount projectFiles={cell.getValue() ?? []} />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    enableSorting: false,
    meta: {
      field: field,
      filterType: filterType.NUMBER,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

// ===================
type NumberColumn = {
  total_units: number
  hours_per_unit: number
  productivity: number
  effective_working_hours: number
  total_working_hours: number
  number_of_workers: number
  duration_in_hours: number
  duration: number
  planned_execution_hours: number
  planned_staffing: number
  average_staffing: number
  delay: number
}

const numberHelper = createColumnHelper<NumberColumn>()

export const numberColumn = (field: keyof NumberColumn, meta: CustomMeta) =>
  numberHelper.accessor(field, {
    id: field,
    size: 150,
    cell: ({ cell }) => (
      <span className={styleClass.cell}>{toFixed(cell.getValue())}</span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.NUMBER,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

// ===================
type EditableColumn = {
  name: string
  record_id: string
  endTime: string
  description: string
  location: string
}
const editableHelper = createColumnHelper<EditableColumn>()

export const editableTextColumn = (
  field: keyof EditableColumn,
  meta: CustomMeta,
  onChange: (key: string, value: string) => void,
  disabled: boolean = false,
) =>
  editableHelper.accessor(field, {
    id: field,
    cell: ({ cell, row }) => {
      return (
        <TableTextEditor
          value={cell.getValue()}
          clickToEdit={!disabled}
          disableEdit={disabled}
          onSubmit={(value) => onChange(row.id, value)}
        />
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

export const editableDateColumn = (
  field: keyof EditableColumn,
  meta: CustomMeta,
  onDateChange: (date: string, item: any) => void,
  validate?: (item: any, newDate?: Moment) => string | undefined,
) =>
  editableHelper.accessor(field, {
    id: field,
    cell: ({ cell, row }) => {
      return (
        <DateTimeInput
          inputClassName={styleClass.editableCell}
          width={cell.column.getSize() - 5}
          date={`${cell.getValue() ?? ''}`}
          onSave={(date) => date && onDateChange(date, row.original)}
          validate={(value) => validate?.(row.original, value)}
        />
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.RANGEDATE,
      processFilter: (val: { start: string; end: string }) => ({
        [field]: [val.start, val.end],
      }),
      ...meta,
    },
  })

type BooleanColumn = {
  is_active: boolean
  is_in_bim: boolean
  is_testable: boolean
  isActive: boolean
}
const booleanHelper = createColumnHelper<BooleanColumn>()

export const booleanColumn = (
  field: keyof BooleanColumn,
  meta: CustomMeta,
  onChange: (key: string, value: boolean) => void,
  disableEdit?: boolean,
) =>
  booleanHelper.accessor(field, {
    id: field,
    cell: ({ cell, row }) => {
      return (
        <div
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
          }}
          className={'pl-2 flex items-center'}
        >
          <CheckBox
            disable={disableEdit}
            disableTab={true}
            onChange={(value: boolean) => onChange(row.id, value)}
            valueProp={cell.getValue()}
          />
        </div>
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.BOOLEAN,
      processFilter: (val) => ({
        [field]: val,
      }),
      ...meta,
    },
  })

type MultiValueColumn = {
  systems: { id: number }[]
  requirements: { id: number }[]
  file_containers: { id: number }[]
  connected_systems: { id: number }[]
}

const multiValueHelper = createColumnHelper<MultiValueColumn>()

export const multiValueListSelectorColumn = (
  field: keyof MultiValueColumn,
  meta: CustomMeta,
  identifierField: string,
  onClick?: (selectedIds: number[], id: number) => void,
) =>
  multiValueHelper.accessor(field, {
    id: field,
    size: 250,
    enableSorting: false,
    cell: ({ cell, row }) => (
      <MultiValueListSelector
        values={cell.getValue()}
        field={identifierField}
        onClick={onClick}
        selectedId={+row.id}
      />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

type MultiFilterOptionsColumnType = {
  contract: IContract
  mile_stone: IMilestone
  team: ITeam
}

const multiFilterOptionsHelper =
  createColumnHelper<MultiFilterOptionsColumnType>()

export const multiFilterOptionsColumn = (
  field: keyof MultiFilterOptionsColumnType,
  meta: CustomMeta,
  displayFields: string[],
) =>
  multiFilterOptionsHelper.accessor(field, {
    id: field,
    size: 250,
    cell: ({ cell }) =>
      cell.getValue() ? (
        <span className={styleClass.cell}>
          {displayFields.map((value) => `${cell.getValue()[value]}` + ' ')}
        </span>
      ) : (
        ''
      ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

type MultiFilterOptionsColumnTypeEditable = {
  key_point: IKeypoint
  tag: ITag
  improvement: IImprovement
}

const multiFilterOptionsEditableHelper =
  createColumnHelper<MultiFilterOptionsColumnTypeEditable>()

export const multiFilterOptionsColumnEditable = <T extends object>(
  field: keyof MultiFilterOptionsColumnTypeEditable,
  meta: CustomMeta,
  displayFields: string[],
  getData: () => Promise<T>,
  onChange: (key: number, value: string) => void,
) =>
  multiFilterOptionsEditableHelper.accessor(field, {
    id: field,
    size: 250,
    cell: ({ cell, row }) => (
      <TableSelectorEditor
        rowId={row.original['id']}
        selected={cell.getValue()}
        getData={getData}
        dataFields={displayFields}
        isUserSelector={false}
        onDataSelected={(value) => onChange(row.original['id'], value.id)}
        displayContent={
          cell.getValue() ? (
            <span className={styleClass.cell}>
              {displayFields.map((value) => `${cell.getValue()[value]}` + ' ')}
            </span>
          ) : (
            ''
          )
        }
      />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

type DualSelectorColumnTypes = {
  responsible: IUserData
  discipline: Discipline
  main_process: IMainProcess
  team: ITeam
}

const dualSelectorColumnHelper = createColumnHelper<DualSelectorColumnTypes>()

export const dualSelectorColumn = <T extends object>(
  field: keyof DualSelectorColumnTypes,
  secondaryField: keyof DualSelectorColumnTypes,
  meta: CustomMeta,
  displayFields: string[],
  displayFieldsSecondary: string[],
  getAPI: IGetAPI,
  onDataSelected: (id: number, primary: number, secondary: number) => void,
  displayContent?: (primaryObject?: T) => JSX.Element,
  fieldsOptional: IIsOptional = { primary: true, secondary: true },
  primaryUserSelector: boolean = false,
  secondaryUserSelector: boolean = false,
) =>
  dualSelectorColumnHelper.accessor(field, {
    id: field,
    size: 250,
    cell: ({ cell, row }) => (
      <DualTableSelector
        rowId={row.original['id']}
        selected={{
          primary: row.original[field],
          secondary: row.original[secondaryField],
        }}
        getAPI={getAPI}
        label={{ primary: field, secondary: secondaryField }}
        dataFields={{
          primary: displayFields,
          secondary: displayFieldsSecondary,
        }}
        onDataSelected={onDataSelected}
        displayContent={
          displayContent ? (
            displayContent(row.original[field] as T)
          ) : cell.getValue() ? (
            <span>
              {displayFields.map((value) => `${cell.getValue()[value]}` + ' ')}
            </span>
          ) : (
            ''
          )
        }
        fieldsOptional={fieldsOptional}
        primaryUserSelector={primaryUserSelector}
        secondaryUserSelector={secondaryUserSelector}
      />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

type StatusColumnType = {
  status: IStatusCell
}

const statusColumnHelper = createColumnHelper<StatusColumnType>()

export const statusColumn = (
  field: keyof StatusColumnType,
  dataFields: (keyof IStatusCell)[],
  meta: CustomMeta,
  statusTypes: IStatusTypes[],
  onStatusSelect?: (
    statusTypes: IStatusTypes,
    key: number,
    cell: IStatusCell,
  ) => void,
  selectColor?: (cell: IStatusCell) => BadgeColor,
) =>
  statusColumnHelper.accessor(field, {
    id: field,
    size: 130,
    cell: ({ row }) => {
      const statusCell = dataFields.reduce((acc, field) => {
        acc[field] = row.original[field]
        return acc
      }, {})
      return (
        <TableStatusLabel
          disableEdit={!!onStatusSelect}
          statusTypes={statusTypes}
          cell={statusCell as IStatusCell}
          rowId={row.original['id']}
          onStatusSelect={onStatusSelect}
          showDropdownAlways={true}
          selectColor={selectColor}
        />
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      getFilter: () => {
        return new Promise((resolve) => {
          resolve(
            statusTypes.map((status) => {
              return {
                value: status.id,
                id: status.id,
                name: status.name,
                active: false,
              }
            }),
          )
        })
      },
      ...meta,
    },
  })

type UserColumnType = {
  user: IUserData
  whodunnit: IUserData
  responsible: IUserData
}

const userColumnHelper = createColumnHelper<UserColumnType>()

export const userColumn = (field: keyof UserColumnType, meta: CustomMeta) =>
  userColumnHelper.accessor(field, {
    id: field,
    size: 200,
    cell: ({ cell }) =>
      cell.getValue() ? (
        <div className={styleClass.cell}>
          <UserIcon
            userId={cell.getValue().id}
            firstName={cell.getValue().firstName}
            lastName={cell.getValue().lastName}
            image_url={cell.getValue().image_url}
          />
        </div>
      ) : (
        <span />
      ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

type TaskCountColumnType = {
  open_children: number
}

const taskCountHelper = createColumnHelper<TaskCountColumnType>()

export const taskCountColumn = (meta: CustomMeta) =>
  taskCountHelper.accessor('open_children', {
    id: 'open_children',
    size: 150,
    enableColumnFilter: false,
    cell: ({ cell, row }) => {
      return (
        <span
          className={classNames(
            styleClass.cell,
            row.original['done_children'] > 0 && 'text-red-two',
          )}
        >
          {row.original['done_children']}/
          {row.original['done_children'] + cell.getValue()}
        </span>
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: 'open_children',
      ...meta,
    },
  })

type EnumColumnType = {
  item_type: string
  event: string
}

type EnumType = {
  id: string
  name: string
}

const enumColumnHelper = createColumnHelper<EnumColumnType>()

export const enumColumn = (
  field: keyof EnumColumnType,
  meta: CustomMeta,
  enumTypes: EnumType[],
  displayCell: (cellValue?: string) => string,
) =>
  enumColumnHelper.accessor(field, {
    id: field,
    size: 100,
    cell: ({ cell }) => (
      <span className={twMerge(styleClass.cell, 'first-capitalize')}>
        {t(displayCell(cell.getValue()))}
      </span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      getFilter: () => {
        return new Promise((resolve) => {
          resolve(
            enumTypes.map((type) => {
              return {
                value: type.id,
                id: type.id,
                name: type.name,
                active: false,
              }
            }),
          )
        })
      },
      ...meta,
    },
  })

interface IAttachment {
  name?: string
  link?: string
}

const linkHelper = createColumnHelper<IAttachment>()

export const linkColumn = (field: keyof IAttachment, meta: CustomMeta) =>
  linkHelper.accessor(field, {
    id: field,
    size: 250,

    cell: ({ cell, row }) => (
      <span
        onClick={openFile(row.original.link ?? '')}
        className={'text-blue-root underline px-3'}
      >
        {cell.getValue()}
      </span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      ...meta,
    },
  })
